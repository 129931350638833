import React, { useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Flex, Box } from 'grid-styled';
import { has } from 'lodash';

import Table from 'components/Table';

import ExportToCVSButton from 'containers/ExportToCSVButton';
import withTableResources from 'containers/TableStateContainer';

import { openInNewTab } from 'utils/index';
import { MAX_REPORT_SIZE_LIMIT } from 'utils/constants';
import { FiltersProvider, DateRangesFilter, DateRangesFilterActions } from './PhotoOrdersFilterProvider';

const PhotoIngestionReport = (props) => {
  const {
    tableState,
    onFilteredChange,
    onSortedChange,
    onPageChange,
    onPageSizeChange,
    onBeforeRequestDataSetup,
    onFetchData,
  } = props;

  useEffect(() => {
    props.onFetchData();
  }, []);

  return (
    <FiltersProvider>
      <div className="reports">
        <Flex>
          <Box pb={20} pt={40} ml={2}>
            <p>View orders that have been placed and ingested by the photo ordering service.</p>
            <ul>
              <li>Text filters must be exact matches.</li>
            </ul>
            <p>
              <i>
                Note: Your search results are limited to approximately 1000 records. To ensure that you receive all
                relevant results, you may need to modify or refine your search parameters.
              </i>
            </p>
          </Box>
        </Flex>
        <Flex flexDirection="column">
          <Box pb={20} pt={40}>
            <DateRangesFilter />
          </Box>
        </Flex>
        <Flex flexDirection="column">
          <Box pb={20}>
            <DateRangesFilterActions onBeforeRequestDataSetup={onBeforeRequestDataSetup} onFetchData={onFetchData} />
          </Box>
        </Flex>

        <Table
          data={tableState.data}
          columns={tableState.columns}
          page={tableState.page}
          pages={tableState.pages}
          defaultPageSize={'100'}
          filtered={tableState.filtered}
          sorted={tableState.sorted}
          loading={tableState.loading}
          onFilteredChange={onFilteredChange}
          onSortedChange={onSortedChange}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          showPaginationBottom={false}
          getTdProps={(state, rowInfo, column) => {
            const style = {
              cursor: 'pointer',
            };
            if (column.id === 'address') {
              // eslint-disable-next-line react/display-name
              column.Cell = () => (
                <Link to={`/property/${rowInfo.original.slug}/photos`}>{rowInfo.original.address}</Link>
              );
              return {};
            } else if (column.id === 'feed_attempts' && rowInfo && rowInfo.original.feed_attempts_met === 'Yes') {
              style['backgroundColor'] = '#fcf';
            }
            return {
              style: style,
              onClick: () => {
                has(rowInfo, 'original') && openInNewTab(`/property/${rowInfo.original.slug}/photos`);
              },
            };
          }}
        />
        {ExportToCVSButton({
          state: tableState,
          maxReportSizeLimit: MAX_REPORT_SIZE_LIMIT,
          endpoint: '/api/admin/photo-ingestion',
          reportName: 'photo-ingestion-report',
        })}
      </div>
    </FiltersProvider>
  );
};

PhotoIngestionReport.propTypes = {
  // inherited from withRouter
  history: PropTypes.object,

  // inherited from withTableResources
  tableState: PropTypes.object,
  onFetchData: PropTypes.func,
  onFilteredChange: PropTypes.func,
  onSortedChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  showLoading: PropTypes.func,
  hideLoading: PropTypes.func,
  onBeforeRequestDataSetup: PropTypes.func,
};

export default withTableResources(withRouter(PhotoIngestionReport), {
  endpoint: '/api/admin/photo-ingestion',
  isReport: true,
});
