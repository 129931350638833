import React, { useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Box } from 'grid-styled';
import { Button, Checkbox, Image } from 'semantic-ui-react';
import { Input } from 'reactstrap';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { toggleClassName } from 'utils/photos';

/**
 * The image itself, with the controls for labeling professional or active.
 */
const SortableItem = ({
  tags,
  onUpdateTag,
  makePrimary,
  index,
  image,
  hasPermission,
  handleCheckbox,
  deletePhoto,
  downloadPhoto,
  selectedPhotos,
  selectPhoto,
}) => {
  const sortable = useSortable({ id: image.id });
  const { attributes, listeners, setNodeRef, transform, transition } = sortable;
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [state, setState] = useState({
    hovering: false,
    selected: [],
  });

  const isSelected = selectedPhotos.some((item) => item.id === image.id);

  const onImageHover = () => {
    if (!isSelected) {
      setState({
        ...state,
        hovering: true,
      });
    }
  };

  const onImageUnhover = () => {
    setState({
      ...state,
      hovering: false,
    });
  };
  const onSelect = (e) => {
    if (hasPermission) {
      selectPhoto(image.id, image.property_id, e.shiftKey);
    }
  };

  const tagOptions = () => {
    if (state.hovering && hasPermission) {
      return (
        <div>
          <div className="sortable-item__actions">
            <Input
              type="select"
              name="selectMulti"
              id="exampleSelectMulti"
              onChange={(e) => onUpdateTag(e, index)}
              value={get(image, 'tags[0].name')}
            >
              <option value={''}>Select a Tag</option>
              {tags.map((e, i) => {
                return (
                  <option key={i} value={e.name}>
                    {e.name}
                  </option>
                );
              })}
            </Input>
            <Checkbox
              label="Professional"
              className="sortable-checkbox"
              checked={image.pro}
              onChange={() => {
                return handleCheckbox('pro', index);
              }}
            />
            <Checkbox
              label="Hidden"
              className="sortable-checkbox"
              checked={image.hidden}
              onChange={() => handleCheckbox('hidden', index)}
            />
            <Checkbox
              label="Floorplan"
              className="sortable-checkbox"
              checked={image.type === 'floorplan'}
              onChange={() => {
                return handleCheckbox('floorplan', index);
              }}
            />
          </div>
          <div className="buttons__container">
            <Button negative onClick={() => deletePhoto(index)} className="delete-photo">
              Delete
            </Button>

            <Button onClick={() => makePrimary(index)} className="ui primary button">
              Make Primary
            </Button>
            {image.hidden ? (
              ''
            ) : (
              <Button onClick={() => downloadPhoto(index)} className="ui button">
                Download Photo
              </Button>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <Box
      {...attributes}
      {...listeners}
      style={style}
      ref={setNodeRef}
      p={10}
      id={`${
        ['tour-embedded', 'tour-direct', 'tourStock'].includes(image.type)
          ? 'sortable-item-tour'
          : image.type === 'floorplan'
            ? 'sortable-item-floorplan'
            : ''
      }`}
      className={`${
        ['tour-embedded', 'tour-direct', 'tourStock'].includes(image.type)
          ? `sortable-item-tour sortable-item-tour-${image.order}`
          : image.type === 'floorplan'
            ? `sortable-item-floorplan sortable-item-floorplan-${image.order}`
            : ''
      } sortable-item`}
      onMouseEnter={onImageHover}
      onMouseLeave={onImageUnhover}
    >
      <Image className={toggleClassName(image, isSelected)} src={image.image_url} onClick={onSelect} />
      {tagOptions()}
    </Box>
  );
};

export default SortableItem;

SortableItem.propTypes = {
  image: PropTypes.object,
  onUpdateTag: PropTypes.func,
  makePrimary: PropTypes.func,
  tags: PropTypes.array,
  handleCheckbox: PropTypes.func,
  index: PropTypes.number,
  deletePhoto: PropTypes.func,
  downloadPhoto: PropTypes.func,
  hasPermission: PropTypes.bool,
  selectedPhotos: PropTypes.array,
  selectPhoto: PropTypes.func,
};
