import React from 'react';
import { Box } from '@material-ui/core';
import { usePhotoOrdersReportFilters } from './FiltersContext';
import moment from 'moment';
import { transform } from 'lodash';
import PropTypes from 'prop-types';
import { isDateRangeInvalid, invalidAny } from 'utils/reports';

const DateRangesFilterActions = ({ onBeforeRequestDataSetup, onFetchData }) => {
  const {
    state: { createdAt, updatedAt, completedAt },
    actions: { setCreatedAtDatesError, setUpdatedAtDatesError, setCompletedAtDatesError },
  } = usePhotoOrdersReportFilters();

  const validDates = () => {
    let createdAtInvalid = invalidAny(createdAt);
    let createdAtError = createdAtInvalid ? 'Enter a valid date' : '';
    let updatedAtInvalid = invalidAny(updatedAt);
    let updatedAtError = createdAtInvalid ? 'Enter a valid date' : '';
    let completedAtInvalid = invalidAny(completedAt);
    let completedAtError = createdAtInvalid ? 'Enter a valid date' : '';

    if (!createdAtInvalid && createdAt.startDate && createdAt.endDate) {
      createdAtInvalid = isDateRangeInvalid(createdAt.startDate, createdAt.endDate);

      createdAtError = createdAtInvalid ? 'End date should be greater' : '';
    }

    if (!updatedAtInvalid && updatedAt.startDate && updatedAt.endDate) {
      updatedAtInvalid = isDateRangeInvalid(updatedAt.startDate, updatedAt.endDate);

      updatedAtError = updatedAtInvalid ? 'End date should be greater' : '';
    }

    if (!completedAtInvalid && completedAt.startDate && completedAt.endDate) {
      completedAtInvalid = isDateRangeInvalid(completedAt.startDate, completedAt.endDate);

      completedAtError = completedAtInvalid ? 'End date should be greater' : '';
    }

    setCreatedAtDatesError(createdAtError);
    setUpdatedAtDatesError(updatedAtError);
    setCompletedAtDatesError(completedAtError);

    return !createdAtInvalid && !updatedAtInvalid && !completedAtInvalid;
  };

  const onApplyClick = (e) => {
    e.preventDefault();
    if (validDates()) {
      onBeforeRequestDataSetup(mapToPlainFormattedDates({ createdAt, updatedAt, completedAt }));
      onFetchData();
    }
  };

  return (
    <Box ml={'4%'}>
      <button type="button" className="ui primary button" onClick={onApplyClick}>
        Apply Filter
      </button>
    </Box>
  );
};

/**
 * Maps dates structure to be more frienldy for payload.
 *
 * @param {*} dates
 *
 * @returns Plain object with start and end dates for all categories.
 * @example
 * dates = {
 *   createdAt: { startDate: '01/11/2021', endDate: '01/12/2021' },
 *   updatedAt: { startDate: '01/15/2021', endDate: '01/18/2021' },
 *   completedAt: { startDate: '01/21/2021', endDate: '01/22/2021' }
 * }
 *
 * mapToPlainDates(dates);
 * =>
 * {
 *  createdAtStartDate: '01-11-2021',
 *  createdAtEndDate: '01-12-2021',
 *  updatedAtStartDate: '01-15-2021',
 *  updatedAtEndDate: '01-18-2021',
 *  completedAtStartDate: '01-21-2021',
 *  completedAtEndDate: '01-22-2021',
 * }
 */
function mapToPlainFormattedDates(dates) {
  return transform(
    dates,
    (mappedDates, v, k) => {
      if (v['startDate']) {
        mappedDates[`${k}StartDate`] = moment(v['startDate']).format('MM-DD-YYYY');
      }

      if (v['endDate']) {
        mappedDates[`${k}EndDate`] = moment(v['endDate']).format('MM-DD-YYYY');
      }
    },
    {},
  );
}

DateRangesFilterActions.propTypes = {
  onBeforeRequestDataSetup: PropTypes.func,
  onFetchData: PropTypes.func,
};

export default DateRangesFilterActions;
