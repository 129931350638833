import React from 'react';
import { Flex } from 'grid-styled';
import PropTypes from 'prop-types';
import { DndContext, closestCenter, useSensor, useSensors, PointerSensor } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import SortableItem from './SortableItem.js';

const SortableList = ({
  items,
  tags,
  hasPermission,
  onChangeOrder,
  onUpdateTag,
  handleCheckbox,
  deletePhoto,
  downloadPhoto,
  makePrimary,
  selectedPhotos,
  selectPhoto,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!active.id || !over.id) {
      return;
    }
    if (active.id === over.id) {
      return;
    }

    const oldIndex = items.findIndex((item) => item.id === active.id);
    const newIndex = items.findIndex((item) => item.id === over.id);
    onChangeOrder({ oldIndex, newIndex });
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <Flex css="margin-bottom: 10px;">
          <Flex flexWrap="wrap" className="image-container">
            {items.map((image, index) => (
              <SortableItem
                key={`${image.id}-${index}`}
                index={index}
                image={image}
                hasPermission={hasPermission}
                deletePhoto={deletePhoto}
                downloadPhoto={downloadPhoto}
                handleCheckbox={handleCheckbox}
                tags={tags}
                makePrimary={makePrimary}
                onUpdateTag={onUpdateTag}
                selectedPhotos={selectedPhotos}
                selectPhoto={selectPhoto}
              />
            ))}
          </Flex>
        </Flex>
      </SortableContext>
    </DndContext>
  );
};
export default SortableList;

SortableList.propTypes = {
  items: PropTypes.array,
  tags: PropTypes.array,
  hasPermission: PropTypes.bool,
  onChangeOrder: PropTypes.func,
  onUpdateTag: PropTypes.func,
  handleCheckbox: PropTypes.func,
  deletePhoto: PropTypes.func,
  downloadPhoto: PropTypes.func,
  makePrimary: PropTypes.func,
  selectedPhotos: PropTypes.array,
  selectPhoto: PropTypes.func,
};
