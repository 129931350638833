import React from 'react';
import View from '../../components/View';
import ViewHeader from '../../components/ViewHeader';
import ViewContent from '../../components/ViewContent';
import PhotoOrderBulkRequest from 'components/PhotoOrderBulkRequest';

const PlanomaticBulkOrder = () => {
  return (
    <View>
      <ViewHeader title="Planomatic Bulk Ordering" subtitle="Upload a csv file to bulk place photo orders"></ViewHeader>

      <ViewContent>
        <PhotoOrderBulkRequest />
      </ViewContent>
    </View>
  );
};

export default PlanomaticBulkOrder;
