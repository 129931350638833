import React, { Component, Fragment } from 'react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import axios from 'utils/axios';
import { toSnakeCase } from 'utils/index';
import Select from 'react-select';
import { ConfirmModal } from '@invitation-homes/styled-ui';
import moment from 'moment';
import { DebounceInput } from 'react-debounce-input';
import deleteIcon from 'icons/delete.svg';
import { Link } from 'react-router-dom';
import './table-overrides.scss';
import InlineMessage from '../components/InlineMessage';

const withTableResources = (TableToEnhance, config) => {
  return class TableStateContainer extends Component {
    static propTypes = {
      pageSize: PropTypes.number,
      cellProps: PropTypes.func,
      mockData: PropTypes.shape({
        data: PropTypes.array,
        columnData: PropTypes.array,
        page: PropTypes.number,
        pages: PropTypes.number,
        records: PropTypes.array,
        columns: PropTypes.array,
      }),
      permissions: PropTypes.object,
    };

    constructor(props) {
      super(props);
      this.state = {
        offset: 0,
        loading: true,
        hasFilters: false,
        data: [],
        columnData: [],
        columns: config.canDelete
          ? [
              {
                Header: 'Delete',
                label: 'Delete',
                accessor: 'delete',
                filterable: false,
                key: 'delete',
              },
            ]
          : [],
        page: 0, // zero index
        pages: 1,
        pageSize: props.pageSize || 25,
        filtered: [],
        sorted: [],
        total: 0,
        endpoint: config.endpoint,
        isReport: config.isReport || false,
        showModal: false,
        rowId: '',
        user_id: false,
        canManageFeeds: props.permissions && Object.keys(props.permissions).includes('canManageFeeds'),
        canCreateFeeds: props.permissions && Object.keys(props.permissions).includes('canCreateFeeds'),
        errorMessage: '',
      };

      this._additionalTableDataObj = {};
    }

    onBeforeRequestDataSetup = (opts) => {
      this.additionalTableDataObj = opts;
    };

    onFetchData = (opts = {}) => {
      this.setState({ loading: true });
      opts = {
        page: this.state.page,
        pageSize: this.state.pageSize,
        pages: this.state.pages,
        sorted: this.state.sorted,
        filtered: this.state.filtered,
        ...opts,
      };

      if (this.props.mockData) {
        /* start of mocking data */
        const mockData = this.props.mockData;
        const page = mockData.page ? mockData.page - 1 : opts.page;
        const pages = mockData.pages ? mockData.pages : opts.pages;
        const data = mockData.records;
        const columnData = mockData.columns;

        setTimeout(() => {
          this.setState({
            pageSize: opts.pageSize,
            sorted: opts.sorted,
            filtered: opts.filtered,
            page,
            pages,
          });
          this.updateData(data, columnData);
        }, 250);

        /* end of mocking data */
      } else {
        /* start of axios request */

        // get the filters in the format the API expects
        const filters = {};
        opts.filtered &&
          opts.filtered.forEach((f) => {
            let value;
            if (Array.isArray(f.value)) {
              // don't send the API empty arrays
              if (f.value.length > 0) {
                value = f.value.map((e) => e.value);
              }
            } else {
              if (typeof f.value !== 'undefined') {
                if (typeof f.value.value !== 'undefined') {
                  value = f.value.value;
                } else {
                  value = f.value;
                }
              }
            }

            if (typeof value !== 'undefined') {
              filters[f.id] = value;
            }
          });

        const sorting = {};
        opts.sorted &&
          opts.sorted.forEach((s) => {
            sorting[s.id] = s.desc ? 'desc' : 'asc';
          });

        const options = {
          params: {
            page: opts.page,
            pageSize: opts.pageSize,
            withFilters: true,
            filters: JSON.stringify(filters),
            sorting: JSON.stringify(sorting),
            ...this.additionalTableDataObj,
          },
        };

        return axios
          .get(this.state.endpoint, options)
          .then((res) => {
            this.setState({
              pageSize: opts.pageSize,
              sorted: opts.sorted,
              page: parseInt(res.data.page, 10),
              pages: parseInt(res.data.pages, 10),
              total: parseInt(res.data.total, 0),
            });

            this.updateData(res.data.results, res.data.filters);

            return {
              results: res.data.results,
              filters: res.data.filters,
            };
          })
          .catch((err) => {
            const { status } = err.response;

            if (status === 401) {
              axios.clearLogin();
            }
          });
        /* end of axios request */
      }
    };

    onFilteredChange = (filtered) => {
      const { pageSize, sorted, pages } = this.state;
      this.setState({ filtered });
      return this.onFetchData({
        page: 0,
        pageSize,
        pages,
        sorted,
        filtered,
      });
    };

    onSorted = (sorted) => {
      const { page, pageSize, filtered, pages } = this.state;
      this.setState({ loading: true });
      return this.onFetchData({
        page,
        pageSize,
        sorted,
        pages,
        filtered,
      });
    };

    onPageChange = (page) => {
      const { pageSize, sorted, filtered, pages } = this.state;
      this.setState({ loading: true });
      return this.onFetchData({
        page,
        pageSize,
        sorted,
        pages,
        filtered,
      });
    };

    onPageSizeChange = (pageSize, page) => {
      const { sorted, filtered, pages } = this.state;
      this.setState({ loading: true });
      return this.onFetchData({
        page,
        pageSize,
        pages,
        sorted,
        filtered,
      });
    };

    get additionalTableDataObj() {
      return this._additionalTableDataObj;
    }

    set additionalTableDataObj(additionalTableOpts) {
      this._additionalTableDataObj = additionalTableOpts;
    }

    getFilter = (filterData, key) => {
      return ({ filter, onChange }) => {
        if (filterData.type === 'dropdown') {
          const options = filterData.values.map((value) => {
            if (typeof value === 'string') {
              return { label: value, value };
            }
            return value;
          });

          let value;
          if (filterData.multi) {
            value = filter ? filter.value : [];
          } else {
            value = {};
            if (filter && filter.value && filter.value.value) {
              value = filter.value;
            }
          }

          return (
            <Select
              id={key}
              placeholder="Select"
              multi={filterData.multi || false}
              autosize={false}
              joinValues={filterData.multi || false}
              options={options}
              onChange={(e) => {
                onChange(e || []);
              }}
              value={value}
            />
          );
        }
        return (
          <div className="ui input" style={{ width: '100%' }}>
            <DebounceInput
              id={key}
              placeholder="Search"
              debounceTimeout={400}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              value={filter ? filter.value : ''}
            />
          </div>
        );
      };
    };

    setError = (errorMessage) => {
      this.setState({
        errorMessage,
        showModal: false,
      });
    };

    transformColumns = (columns) => {
      const { isReport } = this.state;

      const filterColumns = (column) => (config.columnFilter ? config.columnFilter(column) : true);
      const transformColumn = (column) => (config.columnTransform ? config.columnTransform(column) : column);

      const getCell = (column, row) => {
        if (['created_at', 'updated_at', 'completed_at'].includes(column.key)) {
          return row.value ? <span>{moment(row.value).format('l LT')}</span> : null;
        } else if (isReport && column.key === 'user') {
          return <span>{`${row.value.first_name} ${row.value.last_name}`}</span>;
        } else if (isReport && column.key === 'property') {
          return <div>{row.value.formattedAddress}</div>;
        } else if (['available', 'est_rehab_complete_date'].includes(column.key)) {
          return <span>{row.value !== null && moment(row.value).format('MM/DD/YYYY')}</span>;
        } else if (column.key === 'title') {
          return (
            <Link id={`title_${toSnakeCase(row.value)}`} to={`${config.route}/${row.original.id}`}>
              {row.value}
            </Link>
          );
        } else if (column.key === 'ableToCompleteAppt') {
          return <span>{row.value}</span>;
        } else if (column.key === 'unableToCompleteApptReason') {
          return <span>{row.value}</span>;
        } else if (column.key === 'delete') {
          return (
            <button
              id={`delete_${toSnakeCase(row.original.title)}`}
              className="cursor-pointer delete"
              onClick={() => this.toggleConfirmModal(row.original.id, row.original.user_id)}
            >
              <img alt="delete" src={deleteIcon} />
            </button>
          );
        } else if (column.key === 'feed_properties_count') {
          return <span>{row.original.feed_properties_count}</span>;
        }
        return <div id={toSnakeCase(`${column.key}${row.value}`)}>{row.value}</div>;
      };

      const setColumnAttributes = (columnAttrs, column) => {
        if (!columnAttrs.Filter) {
          if (column.filter) {
            columnAttrs.Filter = this.getFilter(column.filter, column.key);
          } else {
            columnAttrs.filterable = false;
          }
        }

        if (column.width && !columnAttrs.width) {
          columnAttrs.width = column.width;
        }

        if (column.minWidth && !columnAttrs.minWidth) {
          columnAttrs.minWidth = column.minWidth;
        }

        return columnAttrs;
      };

      const getColumnAttrs = (column) => {
        const columnAttrs = {
          Header: column.label,
          accessor: column.key,
          Cell: (row) => getCell(column, row),
          minWidth: 200,
          ...(column.attrs || {}),
        };

        return setColumnAttributes(columnAttrs, column);
      };

      return columns.filter(filterColumns).map(transformColumn).map(getColumnAttrs);
    };

    updateData = (data, columnData = null) => {
      const updatedData = data.reduce((accum, item) => {
        const updatedItem = Object.keys(item).reduce((subAccum, propName) => {
          const value = item[propName];
          return {
            ...subAccum,
            [propName]: typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value,
          };
        }, item);
        return [...accum, updatedItem];
      }, []);

      this.setState((prevState) => {
        if (!isEqual(prevState.columnData, columnData)) {
          prevState.columnData = columnData;
          prevState.columns = [...this.transformColumns([...columnData, ...prevState.columns], data)];
        }
        prevState.data = updatedData;
        prevState.loading = false;
        return prevState;
      });
    };

    showLoading() {
      this.setState({ loading: true });
    }

    hideLoading() {
      this.setState({ loading: false });
    }
    deleteRow = () => {
      // TODO: Make this dynamic for any permission
      const { endpoint, rowId, user_id, canCreateFeeds, canManageFeeds } = this.state;

      if (!canManageFeeds && !canCreateFeeds) {
        return this.setError({
          type: 'danger',
          message: 'You do not have permission to delete any rows.',
        });
      }
      // Permission to manage own, not all, but didn't create this row:
      if (!canManageFeeds && canCreateFeeds && user_id !== axios.getUserId()) {
        return this.setError({
          type: 'danger',
          message: 'You do not have permission to do delete this row.',
        });
      }

      return axios.delete(`${endpoint}/${rowId}`).then(({ data }) => {
        this.setState({
          data,
          showModal: false,
        });
      });
    };
    toggleConfirmModal = (id, user_id = false) => {
      this.setState((prevState) => ({
        showModal: !prevState.showModal,
        rowId: id,
        user_id,
        errorMessage: '',
      }));
    };

    render() {
      const { showModal, errorMessage } = this.state;
      return (
        <Fragment>
          {showModal && (
            <ConfirmModal
              name={config.name}
              active={showModal}
              headline={config.headline}
              description={config.description}
              onClose={this.toggleConfirmModal}
              onAction={this.deleteRow}
              actionBtnLabel="Delete"
              cancelBtnLabel="Cancel"
            />
          )}

          {errorMessage && <InlineMessage {...errorMessage} />}

          <TableToEnhance
            tableState={{ ...this.state, ...this.additionalTableDataObj }}
            onBeforeRequestDataSetup={this.onBeforeRequestDataSetup}
            onFetchData={this.onFetchData}
            onFilteredChange={this.onFilteredChange}
            onSortedChange={this.onSorted}
            onPageChange={this.onPageChange}
            onPageSizeChange={this.onPageSizeChange}
            showLoading={this.showLoading}
            hideLoading={this.hideLoading}
            showPaginationBottom={this.showPaginationBottom}
            {...this.props}
          />
        </Fragment>
      );
    }
  };
};

export default withTableResources;
