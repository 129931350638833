import React, { useState, useRef } from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import './style.scss';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

function TableToolbar(props) {
  const { numSelected, title, onClear, onSubmit, isLoading, onExportDuplicated } = props;

  const options = [
    {
      label: 'Submit bulk orders',
      index: 0,
      action: onSubmit({ allowDuplicates: false }),
    },
    {
      label: 'Submit (Allow Duplicates)',
      index: 1,
      action: onSubmit({ allowDuplicates: true }),
    },
  ];

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const anchorRef = useRef(null);

  const classes = useToolbarStyles();

  return (
    <Toolbar className={classes.root}>
      <Grid container spacing={3} justifyContent="space-between" direction="row">
        <Grid item xs={4}>
          {numSelected > 0 ? (
            <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
              {numSelected} selected
            </Typography>
          ) : (
            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
              {title}
            </Typography>
          )}
        </Grid>
        <Grid container xs={8} justifyContent="flex-end" alignItems="center">
          {numSelected > 0 ? (
            <Tooltip title="Delete">
              <IconButton aria-label="delete">
                <Button variant="contained" color="primary" onClick={onExportDuplicated} disabled={isLoading}>
                  Export duplicate orders
                </Button>
              </IconButton>
            </Tooltip>
          ) : (
            <Grid container spacing={3} justifyContent="flex-end">
              <Grid item>
                <ButtonGroup variant="contained" ref={anchorRef} color="primary" aria-label="split button">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={options[selectedIndex].action}
                    disabled={isLoading}
                  >
                    {options[selectedIndex].label}
                  </Button>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => {
                      setOpen((prevOpen) => !prevOpen);
                    }}
                    disabled={isLoading}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  open={open}
                  zIndex="modal"
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  modifiers={{}}
                  style={{ zIndex: 1 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={() => setOpen(false)}>
                          <MenuList>
                            {options.map((option, index) => (
                              <MenuItem
                                key={option.index}
                                selected={option.index === selectedIndex}
                                onClick={() => {
                                  setSelectedIndex(option.index);
                                  setOpen(false);
                                }}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
              <Grid item xs={3}>
                <Button variant="contained" color="primary" onClick={onClear} disabled={isLoading}>
                  Clear
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export default TableToolbar;
