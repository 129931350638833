import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import SortableList from './SortableList.js';

class Images extends Component {
  static propTypes = {
    items: PropTypes.array,
    onUpdateTag: PropTypes.func,
    onChangeOrder: PropTypes.func,
    onChangeCheckbox: PropTypes.func,
    makePrimary: PropTypes.func,
    deletePhoto: PropTypes.func,
    downloadPhoto: PropTypes.func,
    hasPermission: PropTypes.bool,
    tags: PropTypes.array,
    selectedPhotos: PropTypes.array,
    selectPhoto: PropTypes.func,
  };

  state = {
    items: this.props.items,
  };

  render() {
    const { items = [], tags } = this.props;
    return items.length > 0 ? (
      <div>
        <SortableList
          items={items}
          tags={tags}
          hasPermission={this.props.hasPermission}
          onChangeOrder={this.props.onChangeOrder}
          onUpdateTag={this.props.onUpdateTag}
          handleCheckbox={this.props.onChangeCheckbox}
          deletePhoto={this.props.deletePhoto}
          downloadPhoto={this.props.downloadPhoto}
          makePrimary={this.props.makePrimary}
          selectedPhotos={this.props.selectedPhotos}
          selectPhoto={this.props.selectPhoto}
        />
      </div>
    ) : (
      <Message>
        <p>This property does not currently have photos.</p>
      </Message>
    );
  }
}

export default Images;
